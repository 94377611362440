body {
  margin:0; 
  padding:0; 
  font-family: 'Source Sans Pro', sans-serif;
  overflow-x: hidden;
}

a, a:hover, a:visited {
  color: #0071bc;
}

.mapboxgl-map {
  top:0; 
  height: 100vh;
  width:100vw;
  position: fixed;
  z-index: -5;
}

#header {
  display: inline-block;
  margin: .5vh auto;
  width: 100vw;
  padding: 24px .5vh .5vh;
  text-align: center;
  background: linear-gradient(0deg, rgba(13,15,17,0) 0%, rgba(3,15,17,1) 90%);
}

#footer {
  width: 100%;
  min-height: 2vh;
  padding-top: .5vh;
  padding-bottom: .5vh;
  text-align: center;
  line-height: 5px;
  font-size: 13px;
  color:#C5C9CE;
}

.trade-logo {
  background-image: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579214349/trade-commission/AALC_Trade_Commission.svg');
  position: absolute;
  display: flex;
  justify-self: center;
  z-index: 99999;
  height: 75px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center; 
}

.image {
  text-align: right;
}

#features {
  padding-top: 10vh;
  padding-bottom: 10vh;
  z-index: 100;
}

.centered {
  width: 50vw;
  margin: 0 auto;
}

.lefty {
  width: 33vw;
  margin-left: 5vw;
}

.righty {
  width: 33vw;
  margin-left: 62vw;
}

.light {
  color: #444;
  background-color: #fafafa;
}

.dark {
  color: #fafafa;
  padding: 0px;
}

.step {
  padding-bottom: 50vh;
  opacity: 0.25;
}
.step.active {
  opacity: 0.9;
}

.step div {
  line-height: 25px;
  max-width: 350px;
}

.step > div > *:not(h2) {
  background-color: #474F56
}

.step > div > *:not(img):not(h2) {
  padding: 0 0px;
}
​
.step > div > *:last-child:not(img) {
  padding-bottom: 32px
}

.step > .content > *:first-child:not(img):not(h2), .content h3 {
  padding-top: 32px;
}

.content:not(.subtitle) {
  padding-top: 0px;
}

h1 {
  font-family: 'Frank Ruhl Libre', Georgia, serif;
  font-weight: 500;
   margin-bottom: 0 !important;
}

h1 + h2 {
  font-size: 1.5rem;
  font-style: italic;
  opacity: 1;
  color: #C5C9CE;
  margin-top: 0 !important;
  font-weight: normal
}

h1 + h2 + p {
  opacity: .8;
  color: #C5C9CE;
}
​
.step h2 { 
  font-family: 'Frank Ruhl Libre', Georgia, serif;
  font-weight: 400;
  font-size: 32px;
  padding-bottom: 12px;
  margin-bottom: 16px;
  opacity: .7;
  transition: .5s opacity ease-in-out;
  line-height: 1.2;  
}

.step.active h2 {
  opacity: 1;
}

.step h2:after {
  display:block;
  content:'';
  height:2px;
  width:0px;
  background-color:#fff;
  margin-top:12px;
  opacity: .4;
  transition: 1s width ease-in-out;
}

.step.active h2:after {
  width: 80px;
  transition: 1s width ease-in-out;
}

.step h3.subtitle {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom:12px;
  line-height: auto;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: .03rem;
}
​
.step h3 +img {
  padding-top: 12px;
}
​
p {
  font-size: 18px;
  line-height: 1.6;
  color: #f3f3f3;
  margin-top:0px;
}

.desc {
  padding: 25px 25px;
  font-size: 18px;
}

.subtitle + .desc {
  padding: 0 25px 25px;
}

div[id^='inDepth8'] h3.subtitle::before, div[id^='inDepth9'] h3.subtitle::before, div[id^='inDepth11'] h3.subtitle::before, div[id^='inDepth12'] h3.subtitle::before, div[id^='snohomish'] h3.subtitle::before, div[id^='bright'] h3.subtitle::before {
  content:'\27A4';
  display: inline-block;
  position: relative;
  width: 35px;
  height: 12px;
  top: 10px;
  left: 0px;
  transform-origin: 0 0;
  transform: rotateY(0deg) rotate(-45deg); 
  color:#ea7b54;
}

#footer img {
  max-width: 500px;
  margin: 0 auto;
  padding: 0px 16px;
  margin-bottom: 16px;
}
​
#footer p {
 color:#C5C9CE;
 z-index: 200;
}

.step img {
  width: 100%;
  display: block;
}

.content {
  margin-top: 20px;
}

.title {
  margin-bottom: 10px;
  font-family: 'Frank Ruhl Libre', Georgia, serif;
  font-weight: 500;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.4;
  font-size: 20px;
  font-weight: 600;
  padding-left: 25px;
  padding-right: 20px;
}

ul {
  padding: 0px 30px;
}

li {
  padding-top: 0px;
  padding-bottom: 20px;
}

@media (max-width: 750px) {
  #features {
      width: 90vw;
      margin: 0 auto;
  }
}

/* Add legends */

.step#states1990.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579823137/trade-commission/states1990.png');
}

.step#states2018.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579823137/trade-commission/states2018.png');
}

.step#counties200.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579823333/trade-commission/counties200.png');
}

.step#counties100.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579823682/trade-commission/counties100.png');
}


.step#counties100b.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579823682/trade-commission/counties100.png');
}

.step#manuDecline3.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1581093633/trade-commission/counties1990a_v1.png');
}

.step#manuDecline4.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1581093640/trade-commission/counties2018a_v1.png');
}

.step#jobs13.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579823871/trade-commission/frame9.png');
}


.step#growth.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1579823871/trade-commission/us_states_final.png');
}

.step#services5.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1580238209/trade-commission/frame5a_revise3.png');
}

.step#services6.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1580249765/trade-commission/frame5b_revise1.png');
}

.step#inDepth7.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1580249765/trade-commission/frame5b_revise1.png');
  padding-bottom: 20px;
}

.step#inDepth10.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1580249765/trade-commission/frame5b_revise1.png');
  padding-bottom: 10px;
}

.step#manuLoss1.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1580159581/trade-commission/frame11_1.png');
  padding-bottom: 10px;
}

.step#brightSpots14.active:after {
  content: url('https://res.cloudinary.com/csisideaslab/image/upload/v1580249899/trade-commission/frame7_revise.png');
  padding-bottom: 10px;
}

.step:after {
  display:block;
  content:'';
  text-align:center;
  width: 100%;
  position:fixed;
  bottom:32px;
  right: 0;
  opacity: 1;
  transition: 600ms opacity ease-in-out;
  padding-bottom: 50px;
}

.step.active:after {
  opacity:1;
  transition: 600ms opacity ease-in-out;
}
